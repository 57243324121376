import React from 'react';
import './general-page-subheader.scss';
// import headerSampleImage from '../../images/temp/Qanat-Quartier-1.jpg';

import {Link} from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import ReviewImg from "../../images/5-star-google-review-logo.svg";

const GeneralPageSubHeader = (props) => {
    var targetVal = ""
    if(props.bannerHeading == "Contact Us"){
        targetVal = "blank"
    }else{
        targetVal = ""
    }
    return (
        <section className="general-page-intro-section">
            <div className='banner-container'>
                {props.bannerHeading ? 
                <div className='banner-container__content'>
                    <h1 className='banner-container__heading'>{props.bannerHeading}</h1>
                    <p className='banner-container__text'>{props?.bannerLongText ? props?.bannerLongText : props.bannerText}</p>
                    <div className='banner-container__buttons'>
                        {props.bannerButtonOneText ? <Link className='banner-container__button banner-container__button--one' to={props?.bannerButtonOneLink?.linkUrlAddress} target={targetVal}>{props.bannerButtonOneText}</Link> : "" }
                        {props.bannerHeading == "Sell Your Property in Qatar" ? 
                          <Link className='banner-container__button banner-container__button--two' to={"/our-services/real-estate-valuation/#real-estate-valuation-form"}>Book a Valuation</Link>
                        :
                        props.bannerButtonTwoText ? <Link className='banner-container__button banner-container__button--two' to={props?.bannerButtonTwoLink?.linkUrlAddress}>{props.bannerButtonTwoText}</Link> : "" }
                    </div>
                    {props?.showGoogleReviews &&
                        <div class="google-reviews-wrap">
                            <div class="review-logo">
                                <img src={ReviewImg} alt="review us on Google" width="74" height="30" />
                            </div>
                           <p><strong>4.9/5 </strong>Rating from <strong>327</strong> customer reviews</p>
                        </div>
                    }
                </div> : ""}
                { props.image
                ? <div className="image-with-BW-overlay">
                    <GatsbyImage className="image-from-gatsby" image={getImage(props.image)} alt={props.imageAlt} objectFit="cover"/>
                </div>
                : ""}
            </div>
          <div className="breadcrumb-container">
              <span className="hidden-heading">{props.pageName}</span>
              <p>{props.breadcrumb}<strong>{props.pageName}</strong></p>
          </div>
      </section>
    )
}

export default GeneralPageSubHeader
